import styled from 'styled-components';
import { H5, titleSizes } from 'Common/Typography/styled';
import { bp } from 'utils/breakpoints';

const StyledAnchor = styled(H5)`
  cursor: pointer;
  text-decoration: none;
  ${bp('mobile')`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    ${titleSizes.small};
  `};
`;
StyledAnchor.displayName = 'StyledAnchor';

export default StyledAnchor;
