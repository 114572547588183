import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import SideIndex from 'Common/SideIndex';
import useFade from 'Hooks/useFade';
import useHorizontalScroll from 'Hooks/useHorizontalScroll';
import CARDS from './cards';
import Card from './Card';
import {
  Wrapper, CardsWrapper, Title, Background,
} from './styled';

function Services() {
  const [wrapperRef, scrollRef] = useHorizontalScroll();
  const ref = useRef(null);
  const opacity = useFade(ref, 1, 0);
  return (
    <Wrapper ref={wrapperRef}>
      <Background />
      <SideIndex textId="services.sideIndex" opacity={opacity} />
      <Title children={<FormattedMessage id="services.title" />} />
      <CardsWrapper ref={scrollRef}>
        { CARDS.map(({ icon, title, description }) => <Card key={title} Icon={icon} title={title} description={description} />) }
      </CardsWrapper>
    </Wrapper>
  );
}

export default Services;
