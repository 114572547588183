import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Wrapper, StyledH1 } from './styled';

function Chunks(chunks) {
  return <StyledH1 variation="italic" children={chunks} />;
}

function Br() {
  return <br />;
}

function Description() {
  return (
    <Wrapper>
      <StyledH1>
        <FormattedMessage id="home.description" values={{ i: Chunks, br: Br }} />
      </StyledH1>
    </Wrapper>
  );
}

export default Description;
