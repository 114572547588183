import styled from 'styled-components';
import { H4, hSizes, TitleSmall } from 'Common/Typography/styled';
import { bp } from 'utils/breakpoints';
import { ReactComponent as MinusIcon } from 'Images/icons/minus.svg';
import { ReactComponent as PlusIcon } from 'Images/icons/plus.svg';

export const Wrapper = styled.div`
  display: flex;
  ${bp('mobile')` display: none;`};
  position: absolute;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  bottom: 0px;
  left: 0px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 60px;
  flex-direction: column;
  overflow: hidden;
  transition: background-color 300ms ease-in-out;
  ${({ isExpanded }) => (isExpanded && 'background-color: rgba(0,0,0, 0.45);')};
  & > :last-child{
    margin-top: 16px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Description = styled(TitleSmall)`
  overflow: hidden;
  ${({ isExpanded }) => (isExpanded && `
    max-height: 500px;
    -webkit-transition: max-height 0.7s ease-in-out;
    -ms-transition: max-height 0.7s ease-in-out;
    transition: max-height 0.7s ease-in-out;
  `)};
  ${({ isExpanded }) => (!isExpanded && `
    max-height: 0;
    -webkit-transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    -ms-transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  `)};
`;
Description.displayName = 'Description';

export const Title = styled(H4)`
  ${bp('mobile')`
    ${hSizes.h6};
  `};
`;
Title.displayName = 'Title';

export const Role = styled(H4)`
  ${bp('mobile')`
    ${hSizes.h6};
  `};
`;
Role.displayName = 'Role';

export const RoleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
RoleWrapper.displayName = 'RoleWrapper';

export const Minus = styled(MinusIcon)`
  margin-top: 5px;
  margin-left: 5px;
`;
Minus.displayName = 'Minus';

export const Plus = styled(PlusIcon)`
  margin-top: 5px;
  margin-left: 5px;
`;
Plus.displayName = 'Plus';
