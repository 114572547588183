import styled from 'styled-components';
import { ReactComponent as BrosoftLogo } from 'Images/icons/brosoft_logo.svg';
import { bp } from 'utils/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex: 0 0 62px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  ${bp('mobile')`
    flex: 0 0 30px;
  `};
`;
Wrapper.displayName = 'Wrapper';

export const Logo = styled(BrosoftLogo)`
  flex: 0 0 271px;
  height: 100%;
  ${bp('mobile')`
    flex: 0 0 128px;
  `};
`;
Logo.displayName = 'logo';

export const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  & > :not(:last-child){
    margin-right: 30px;
  }
`;
SectionsWrapper.displayName = 'SectionsWrapper';
