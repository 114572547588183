const layout = {
  mobile: {
    paddingTop: '40px',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '24px',
  },
  tablet: {
    paddingTop: '96px',
    paddingLeft: '32px',
    paddingRight: '32px',
    paddingBottom: '40px',
  },
  notebook: {
    paddingTop: '60px',
    paddingLeft: '100px',
    paddingRight: '100px',
    paddingBottom: '40px',
  },
  desktop: {
    paddingTop: '120px',
    paddingLeft: '200px',
    paddingRight: '200px',
    paddingBottom: '40px',
  },
};

export default layout;
