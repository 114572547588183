import styled from 'styled-components';
import { H1, hSizes } from 'Common/Typography/styled';
import { bp } from 'utils/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  text-align: center;
  ${bp('mobile')`
    margin: auto;
    flex: unset;
    align-items: unset;
  `};
`;
Wrapper.displayName = 'Wrapper';

export const StyledH1 = styled(H1)`
  line-height: 120%;
  letter-spacing: -0.34375px;
  ${bp('mobile')`
    ${hSizes.h3};
  `};
`;
StyledH1.displayName = 'StyledH1';
