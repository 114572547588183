import React from 'react';
import {
  Wrapper, Icon, Number,
} from './styled';

function Whatsapp() {
  return (
    <Wrapper href="https://api.whatsapp.com/send/?phone=5491161451533" target="_blank">
      <Icon />
      <Number children="+5491161451533" />
    </Wrapper>
  );
}

export default Whatsapp;
