import styled, { keyframes } from 'styled-components';
import { bp } from 'utils/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 100%;
  height: 100%;
  overflow: hidden;
  align-items: flex-start;
  ${bp('tablet')`
    flex-direction: column-reverse;
    align-items: center;
  `};
  ${bp('mobile')`
    overflow: unset;
    width: 100%;
    height: 74px;
    flex: 0 0 74px;
  `};
`;
Wrapper.displayName = 'Wrapper';

const lineCollapse = keyframes`
  0% { transform: scaleX(0); }
  75% { transform: scaleX(1); }
`;

export const StyledAnimation = styled.svg`
  ${bp('tablet')`
    max-width: 100%;
  `};
  ${bp('mobile')`display: none;`};
  height: 100%;
  flex: 3 1 0px;

  /*------------------------- STEP 01 -------------------------*/
  
  [id='01_topFace'],
  [id='01_bottomCenterFace'],
  [id='01_topHand'] {
    transform-box: fill-box;
    transform-origin: 50% 100%;
    animation: 700ms ease-in-out infinite alternate running ${keyframes`to { transform: rotate(3deg); }`};
  }

  [id='01_bottomRightFace'],
  [id='01_bottomLeftFace'] {
    transform-box: fill-box;
    transform-origin: 50% 100%;
    animation: 700ms ease-in-out infinite alternate running ${keyframes`to { transform: rotate(-3deg); }`};
  }

  /*------------------------- STEP 02 -------------------------*/

  [id='02_personaje_2_dedo_der'] {
    transform-box: fill-box;
    transform-origin: top;
    animation: 500ms ease-in-out infinite alternate running ${keyframes`to { transform: rotate(-25deg); }`};
  }
  [id='02_personaje_2_dedo_izq'] {
    transform-box: fill-box;
    transform-origin: right;
    animation: 500ms ease-in-out infinite alternate running ${keyframes`to { transform: rotate(-25deg); }`};
  }

  [id='02_personaje_3_brazo_der'] {
    transform-box: fill-box;
    transform-origin: right;
    animation: 1s ease-in-out infinite alternate running ${keyframes`to { transform: rotate(-5deg); }`};
  }
  [id='02_personaje_3_mano_der'] {
    transform-box: fill-box;
    transform-origin: right;
    animation: 1s ease-in-out infinite alternate running ${keyframes`to { transform: rotate(-10deg); }`};
  }
  [id='02_personaje_3_brazo_izq'] {
    transform-box: fill-box;
    transform-origin: bottom;
    animation: 1s ease-in-out infinite alternate running ${keyframes`to { transform: rotate(5deg); }`};
  }
  [id='02_personaje_3_mano_izq'] {
    transform-box: fill-box;
    transform-origin: bottom;
    animation: 1s ease-in-out infinite alternate running ${keyframes`to { transform: rotate(10deg); }`};
  }

  [id='02_personaje_4_brazo_der'] {
    transform-box: fill-box;
    transform-origin: bottom;
    animation: 1s ease-in-out infinite alternate running ${keyframes`to { transform: rotate(-5deg); }`};
  }
  [id='02_personaje_4_mano_der'] {
    transform-box: fill-box;
    transform-origin: bottom;
    animation: 1s ease-in-out infinite alternate running ${keyframes`to { transform: rotate(-10deg); }`};
  }

  /*------------------------- STEP 03 -------------------------*/

  [id='03_pelo']{
    transform-box: fill-box;
    transform-origin: right;
    animation: 1s ease-in-out infinite alternate running ${keyframes`to { transform: rotate(3deg); }`};
  }
  [id='03_dedo_anular']{
    transform-box: fill-box;
    transform-origin: right;
    animation: 200ms ease-in-out infinite alternate running ${keyframes`to { transform: rotate(5deg); }`};
  }

  [id='03_menique'],
  [id='03_dedo_medio'] {
    transform-box: fill-box;
    transform-origin: right;
    animation: 200ms ease-in-out infinite alternate running ${keyframes`to { transform: rotate(-5deg); }`};
  }

  /*------------------------- STEP 04 -------------------------*/

  [id='04_anular_izq'],
  [id='04_indice_izq'],
  [id='04_menique_der'],
  [id='04_medio_der'],
  [id='04_pulgar_der'] {
    transform-box: fill-box;
    transform-origin: bottom;
    animation: 350ms ease-in-out infinite alternate running ${keyframes`to { transform: rotate(-3deg); }`};
  }

  [id='04_menique_izq'],
  [id='04_medio_izq'],
  [id='04_pulgar_izq'],
  [id='04_indice_der'],
  [id='04_anular_der'] {
    transform-box: fill-box;
    transform-origin: bottom;
    animation: 350ms ease-in-out infinite alternate running ${keyframes`to { transform: rotate(3deg); }`};
  }
  
  [id='04_lineas'] {
    transform-box: fill-box;
    transform-origin: left;
    animation: ${lineCollapse} 2s infinite linear;
  }
`;
StyledAnimation.displayName = 'StyledAnimation';
