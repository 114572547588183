import styled from 'styled-components';
import theme from 'Styles/theme';
import { bp } from 'utils/breakpoints';
import { H1, hSizes } from 'Common/Typography/styled';

export const Wrapper = styled.div`
  display: flex;
  ${bp('mobile')`display: none;`};
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  max-width: 600px;
  & > :last-child(){
    margin-top: 16px;
  }
  padding-left: ${theme.layout.desktop.paddingLeft};
  ${bp('notebook')`
    padding-left: ${theme.layout.notebook.paddingLeft};
  `};
  ${bp('tablet')`
    padding-left: ${theme.layout.tablet.paddingLeft};
    margin-top: unset;
  `};
  ${bp('mobile')`
    padding-left: ${theme.layout.mobile.paddingLeft};
    margin-bottom: unset;
  `};
`;
Wrapper.displayName = 'Wrapper';

export const Block = styled.div`
  display: block;
  max-width: 380px;
`;
Block.displayName = 'Block';

export const Title = styled(H1)`
  ${bp('notebook')`
    ${hSizes.h2};
  `};
  ${bp('tablet')`
    ${hSizes.h2};
  `};
  ${bp('mobile')`
    ${hSizes.h3};
  `};
`;
Title.displayName = 'Title';
