import ClientBadge from 'Images/clientBadge.png';
import ClientLuke from 'Images/client-luke.png';
import ClientFede from 'Images/client-fede.jpeg';

const QUOTES = [
  {
    id: 0,
    translations: {
      en: {
        author: 'Lucas Pearson - CTO at Woocar',
        quote: '“Brosoft is the engine of our growth. We built a great work synergy whose results went beyond expectations in qualitative terms.“',
      },
      es: {
        author: 'Lucas Pearson - CTO en Woocar',
        quote: '“Brosoft es el motor de nuestro crecimiento. Construimos una gran sinergia de trabajo cuyos resultados fueron más allá de lo esperado en términos cualitativos.“',
      },
    },
    pic: ClientLuke,
  },
  {
    id: 1,
    translations: {
      en: {
        author: 'Federico Costa - CPO at mGrana',
        quote: '“The Brosoft team stays close to us identifying business needs, beyond the technical aspects, bringing an invaluable boost to our business.“',
      },
      es: {
        author: 'Federico Costa - CPO en mGrana',
        quote: '“El equipo de Brosoft se mantiene cerca de nosotros identificando necesidades de negocio, más allá de los aspectos técnicos, aportando un impulso invalorable a nuestro negocio.“',
      },
    },
    pic: ClientFede,
  },
  {
    id: 2,
    translations: {
      en: {
        author: 'Guillermo Vazquez - Director at DASA',
        quote: '“It is important to stop and value those teams that make a difference in production processes. We value Brosoft\'s effort to achieve constant evolution in customer contentment.“',
      },
      es: {
        author: 'Guillermo Vazquez - Director en DASA',
        quote: '“Es importante detenerse y valorar aquellos equipos que logran diferencia en procesos productivos. Valoramos el esfuerzo de Brosoft por alcanzar una evolución constante en la satisfacción de nuestros clientes.“',
      },
    },
    pic: ClientBadge,
  },
];

export default QUOTES;
