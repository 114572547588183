import styled from 'styled-components';
import { TitleMedium } from 'Common/Typography/styled';
import { bp } from 'utils/breakpoints';
import { ReactComponent as WhatsappSVG } from 'Images/icons/whatsapp.svg';

export const Wrapper = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  ${bp('mobile')`
    margin-bottom: 8px;
  `};
`;
Wrapper.displayName = 'Wrapper';

export const Icon = styled(WhatsappSVG)`
  height: 20px;
  width: 20px;
  margin-right: 15px;
  ${bp('mobile')`
    height: 10px;
    width: 10px;
    margin-right: 7px;
  `};
`;
Icon.displayName = 'Icon';

export const Number = styled(TitleMedium)`
  text-decoration: none;
  ${bp('mobile')`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  `};
`;
Number.displayName = 'Number';
