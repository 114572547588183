/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useCallback } from 'react';
import { get } from 'lodash';
import QUOTES from './quotes';
import Quote from './Quote';
import {
  Wrapper, SliderWrapper, Slider, Next, Prev, Background, DotsWrapper, Dot,
} from './styled';

function Quotes() {
  const [hasTransitionClass, setHasTransitionClass] = useState(true);
  const [leftAndRightDisabled, setLeftAndRightDisabled] = useState(false);
  const [visibleSlide, setVisibleSlide] = useState(1);
  const [stateSlides, setStateSlides] = useState(QUOTES);
  const transitionSpeed = 350;

  useEffect(() => {
    const slidesWithClones = [...QUOTES];
    slidesWithClones.unshift(slidesWithClones[slidesWithClones.length - 1]);
    slidesWithClones.push(slidesWithClones[1]);
    setStateSlides(slidesWithClones);
  }, []);

  const nextSlide = useCallback(() => !leftAndRightDisabled && setVisibleSlide(visibleSlide + 1), [visibleSlide, leftAndRightDisabled]);
  const prevSlide = useCallback(() => !leftAndRightDisabled && setVisibleSlide(visibleSlide - 1), [visibleSlide, leftAndRightDisabled]);

  useEffect(() => {
    if (visibleSlide === stateSlides.length - 1) {
      setLeftAndRightDisabled(true);
      setTimeout(() => {
        setHasTransitionClass(false);
        setVisibleSlide(1);
      }, transitionSpeed);
    }

    if (visibleSlide === 0) {
      setLeftAndRightDisabled(true);
      setTimeout(() => {
        setHasTransitionClass(false);
        setVisibleSlide(stateSlides.length - 2);
      }, transitionSpeed);
    }

    if (visibleSlide === 1 || visibleSlide === stateSlides.length - 2) {
      setTimeout(() => {
        setHasTransitionClass(true);
      }, transitionSpeed);
    }
  }, [visibleSlide]);

  // Whenever the left and right arrows are disabled
  // We want to enable them again after a specific
  // period of time, this is to prevent problematic
  // spamming of these controls during our clone
  // slide-cloning/swapping mechanism
  // Probably a better way to handle this though
  useEffect(() => {
    if (leftAndRightDisabled) {
      setTimeout(() => {
        setLeftAndRightDisabled(false);
      }, transitionSpeed * 2);
    }
  }, [leftAndRightDisabled]);

  useEffect(() => {
    const interval = setInterval(nextSlide, 10000);
    return () => clearInterval(interval);
  }, [nextSlide]);

  return (
    <Wrapper>
      <Background />
      <Next onClick={nextSlide} />
      <Prev onClick={prevSlide} />
      <SliderWrapper>
        <Slider hasTransitionClass={hasTransitionClass} style={{ left: `-${visibleSlide * 100}%` }}>
          { stateSlides.map(({
            translations, pic, id,
          }, index) => {
            const language = get(navigator, 'language', 'en-US').split('-')[0].toLowerCase();
            const { author, quote } = translations[language] || translations.en;
            return <Quote key={`quote_${id}_${index}`} quote={quote} author={author} pic={pic} />;
          })}
        </Slider>
      </SliderWrapper>
      <DotsWrapper>
        { QUOTES.map((_, index) => <Dot key={index} onClick={() => setVisibleSlide(index + 1)} checked={visibleSlide === (index + 1)} />)}
      </DotsWrapper>
    </Wrapper>
  );
}

export default Quotes;
