import enTranslations from './en.json';
import esTranslations from './es.json';

/**
 * Available translations
 * @type array
 */
const collection = [
  {
    locale: 'en',
    title: 'English',
    keys: enTranslations,
  },
  {
    locale: 'es',
    title: 'Español',
    keys: esTranslations,
  },
];

export default collection;
