import { ReactComponent as SoftFixing } from 'Images/icons/icon_software-fixing.svg';
import { ReactComponent as HandyMan } from 'Images/icons/handyman.svg';

const CARDS = [
  {
    icon: SoftFixing,
    title: 'services.cards.softFixing.title',
    description: 'services.cards.softFixing.description',
  },
  {
    icon: SoftFixing,
    title: 'services.cards.managedSoft.title',
    description: 'services.cards.managedSoft.description',
  },
  {
    icon: HandyMan,
    title: 'services.cards.product.title',
    description: 'services.cards.product.description',
  },
  {
    icon: SoftFixing,
    title: 'services.cards.ctoSevices.title',
    description: 'services.cards.ctoSevices.description',
  },
];

export default CARDS;
