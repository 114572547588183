import styled from 'styled-components';
import { H2, hSizes } from 'Common/Typography/styled';
import theme from 'Styles/theme';
import { bp } from 'utils/breakpoints';

const RotatedText = styled(H2)`
  position: fixed;
  bottom: 0px;
  left: 74px;
  transform: rotate(270deg);
  transform-origin: 0 0;
  color: ${theme.colors.grey2};
  ${({ opacity }) => `opacity: ${opacity}`};
  transition: opacity 350ms ease-in-out;
  ${bp('notebook')`
    left: 24px;
    ${hSizes.h4};
  `};
  ${bp('tablet')`
    display: none;
  `};
  ${bp('mobile')`
    display: none;
  `};
`;
RotatedText.displayName = 'RotatedText';

export default RotatedText;
