import React from 'react';
import Home from 'Screens/Home';
import Services from 'Screens/Services';
import WorkMethod from 'Screens/WorkMethod';
import Team from 'Screens/Team';
import Clients from 'Screens/Clients';
import Quotes from 'Screens/Quotes';
import Contact from 'Screens/Contact';
import { Wrapper } from './styled';

function Main() {
  return (
    <Wrapper>
      <Home />
      <Services />
      <WorkMethod />
      <Team />
      <Clients />
      <Quotes />
      <Contact />
    </Wrapper>
  );
}

export default Main;
