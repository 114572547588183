import React from 'react';
import AnchorTag from 'Common/Anchor';
import StyledAnchor from 'Common/Anchor/styled';
import {
  Wrapper, Logo, SectionsWrapper,
} from './styled';
import Brochure from '../../../../Documents/brochure.pdf';

function Header() {
  return (
    <Wrapper>
      <Logo />
      <SectionsWrapper>
        <StyledAnchor as="a" href={Brochure} target="_blank" children="Showcases" download="BrosoftShowcases" />
        <AnchorTag textId="header.section.contact" tagId="contact" />
      </SectionsWrapper>
    </Wrapper>
  );
}

export default Header;
