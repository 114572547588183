import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TitleLarge } from 'Common/Typography/styled';
import { Wrapper, Links, Link } from './styled';

function FollowUs() {
  return (
    <Wrapper>
      <TitleLarge children={<FormattedMessage id="contact.followUs" />} />
      <Links>
        <Link as="a" href="https://www.linkedin.com/company/brosoft-ar" target="_blank" children="LinkedIn" />
        <Link as="a" href="https://instagram.com/brosoftar" target="_blank" children="Instagram" />
      </Links>
    </Wrapper>
  );
}

export default FollowUs;
