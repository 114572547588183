import styled from 'styled-components';
import {
  H2, TitleLarge, hSizes, titleSizes,
} from 'Common/Typography/styled';
import { bp } from 'utils/breakpoints';

export const Wrapper = styled.div`
  flex: 2 1 0px;
  height: 100%;
  margin-left: 24px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${bp('tablet')`
    width: 100%;
    max-height: 250px;
    margin: unset;
    padding-right: unset;
  `};
  ${bp('mobile')`display: none;`};
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled(H2)`
  flex: 0 0 auto;
  ${bp('notebook')`
    ${hSizes.h3};
  `};
  ${bp('tablet')`
    ${hSizes.h3};
  `};
`;
Title.displayName = 'Title';

export const Description = styled(TitleLarge)`
  flex: 1;
  margin-top: 16px;
  overflow: hidden;
  ${bp('notebook')`
    ${titleSizes.medium};
  `};
  ${bp('tablet')`
    ${titleSizes.medium};
  `};
`;
Description.displayName = 'Description';
