import React, { useState, useRef, useEffect } from 'react';
import { Wrapper, StyledAnimation } from './styled';
import Texts from './Texts';

function Step({
  Image, title, description, index, isLastElement,
}) {
  const ref = useRef(null);
  const [glow, setGlow] = useState(false);

  const observer = new IntersectionObserver(((entries) => {
    if (entries[0].isIntersecting) setGlow(true);
    else setGlow(false);
  }), { threshold: [1] });

  useEffect(() => {
    if (ref.current) observer.observe(ref.current);
  }, []);

  return (
    <Wrapper ref={ref}>
      <StyledAnimation children={<Image />} viewBox="0 0 750 466" preserveAspectRatio="xMinYMin meet" />
      <Texts
        index={index}
        glow={glow}
        title={title}
        description={description}
        isLastElement={isLastElement}
      />
    </Wrapper>
  );
}

export default Step;
