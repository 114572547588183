import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Wrapper, Title, Description } from './styled';

function Br() {
  return <br />;
}

function DesktopTexts({ title, description }) {
  return (
    <Wrapper>
      <Title children={<FormattedMessage id={title} />} />
      <Description>
        <FormattedMessage id={description.desktop} values={{ br: Br }} />
      </Description>
    </Wrapper>
  );
}

export default DesktopTexts;
