import styled from 'styled-components';
import { TitleLarge } from 'Common/Typography/styled';
import theme from 'Styles/theme';
import { bp } from 'utils/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  width: 500px;
  margin-right: ${theme.layout.desktop.paddingRight};
  ${bp('notebook')`
    margin-right: ${theme.layout.notebook.paddingRight};
    `};
  ${bp('tablet')`
    margin-right: ${theme.layout.tablet.paddingRight};
    margin-top: auto;
  `};
  ${bp('mobile')`display: none;`};
`;
Wrapper.displayName = 'Wrapper';

export const Links = styled.div`
  margin-top: 16px;
  & > :not(:last-child){
    margin-right: 30px;
  }
`;
Links.displayName = 'Links';

export const Link = styled(TitleLarge)`
  color: ${theme.colors.rose};
`;
Link.displayName = 'Link';
