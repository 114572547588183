import styled from 'styled-components';
import {
  H3, hSizes, TitleMedium, titleSizes,
} from 'Common/Typography/styled';
import { bp } from 'utils/breakpoints';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  z-index: 5;
  flex: 0 0 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
`;
Wrapper.displayName = 'Wrapper';

export const Background = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 1;
`;
Background.displayName = 'Background';

export const ProfilePic = styled.img`
  border-radius: 50%;
  flex: 1;
  overflow: hidden;
  max-height: 320px;
  max-width: 320px;
  object-fit: cover;
  margin-bottom: 24px;
  overflow: hidden;
  ${bp('notebook')`
    max-height: 280px;
    max-width: 280px;
  `};
  ${bp('mobile')`
    max-height: 160px;
    max-width: 160px;
  `};
`;
Background.displayName = 'Background';

export const QuoteText = styled(H3)`
  width: 100%;
  margin-bottom: 18px;
  max-width: 1000px;
  text-align: center;
  ${bp('notebook')`
    ${hSizes.h3};
  `};
  ${bp('mobile')`
    ${hSizes.h6};
  `};
`;
QuoteText.displayName = 'QuoteText';

export const Author = styled(TitleMedium)`
  ${bp('tablet')`
    ${titleSizes.medium};
  `};
  ${bp('mobile')`
    ${titleSizes.small};
  `};
`;
Author.displayName = 'Author';
