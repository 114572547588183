import React from 'react';
import { Wrapper, ContentWrapper, Background } from './styled';
import Header from './components/Header';
import Description from './components/Description';

function Home() {
  return (
    <Wrapper>
      <Background />
      <ContentWrapper>
        <Header />
        <Description />
      </ContentWrapper>
    </Wrapper>
  );
}

export default Home;
