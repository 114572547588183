import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TitleLarge } from 'Common/Typography/styled';
import { Wrapper, Title, Block } from './styled';
import { Link } from '../FollowUs/styled';
import Brochure from '../../../../Documents/brochure.pdf';

function ContactUs() {
  return (
    <Wrapper>
      <Title id="contact" children={<FormattedMessage id="contact.title" />} />
      <Block>
        <TitleLarge children={<FormattedMessage id="contact.description" />} />
        <Link as="a" href={Brochure} target="_blank" children="showcases" download="BrosoftShowcases" />
      </Block>
    </Wrapper>
  );
}

export default ContactUs;
