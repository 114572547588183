import styled from 'styled-components';
import desktopClients from 'Images/desktop/clientes-gradiente-trama.png';
import tabletClients from 'Images/tablet/clientes-gradiente-trama.png';
import mobileClients from 'Images/mobile/clientes-gradiente-trama.png';
import { H1, H3, hSizes } from 'Common/Typography/styled';
import theme from 'Styles/theme';
import { bp } from 'utils/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  width: 100%;
  scroll-snap-align: start;
  position: relative;
  overflow: visible;
  padding-top: ${theme.layout.desktop.paddingTop};
  padding-left: ${theme.layout.desktop.paddingLeft};
  padding-right: ${theme.layout.desktop.paddingRight};
  padding-bottom: ${theme.layout.desktop.paddingBottom};
  ${bp('notebook')`
    padding-top: ${theme.layout.notebook.paddingTop};
    padding-left: ${theme.layout.notebook.paddingLeft};
    padding-right: ${theme.layout.notebook.paddingRight};
    padding-bottom: ${theme.layout.notebook.paddingBottom};
  `};
  ${bp('tablet')`
    padding-top: ${theme.layout.tablet.paddingTop};
    padding-left: ${theme.layout.tablet.paddingLeft};
    padding-right: ${theme.layout.tablet.paddingRight};
    padding-bottom: ${theme.layout.tablet.paddingBottom};
  `};
  ${bp('mobile')`
    padding-top: ${theme.layout.mobile.paddingTop};
    padding-left: ${theme.layout.mobile.paddingLeft};
    padding-right: ${theme.layout.mobile.paddingRight};
    padding-bottom: ${theme.layout.mobile.paddingBottom};
  `};
  & > :nth-child(n+2){
    z-index: 2;
  }
`;
Wrapper.displayName = 'Wrapper';

export const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  max-width: 770px;
  margin: auto;
  ${bp('mobile')`
    margin: unset;
  `};
`;
ViewWrapper.displayName = 'ViewWrapper';

export const Title = styled(H3)`
  display: none;
  width: 100%;
  margin-bottom: 100px;
  ${bp('mobile')`
  display: initial;
  margin-bottom: 50px;
`};
`;
Title.displayName = 'Title';

export const Subtitle = styled(H1)`
  ${bp('notebook')`
    ${hSizes.h3};
  `};
  ${bp('tablet')`
    ${hSizes.h3};
    width: 100%;
    text-align: center;
  `};
  ${bp('mobile')`
    max-width: 250px;
    ${hSizes.h5};
  `};
`;
Subtitle.displayName = 'Subtitle';

export const Background = styled.img`
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0px;
  top: 0px;
  z-index: 1;
  height: 100%;
  width: 100%;
  content: url(${desktopClients});
  ${bp('tablet')`
    width: 100%;
    content: url(${tabletClients});
  `};
  ${bp('mobile')`
    content: url(${mobileClients});
  `};
`;
Background.displayName = 'Background';

export const ClientsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 0 0 auto;
  flex-wrap: wrap;
  overflow: auto;
  margin-top: 32px;
  max-height: 500px;
  ${bp('mobile')`
  max-height: none;
`};
`;
ClientsWrapper.displayName = 'ClientsWrapper';

export const Client = styled.a`
  width: 174px;
  height: 174px;
  padding-right: 12px;
  padding-left: 12px;
  background-image:  ${({ image }) => `url(${image})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  filter: brightness(0) invert(1);
  transition: filter 300ms ease-in-out, opacity 300ms ease-in-out;
  ${bp('mobile')`
    padding-right: 8px;
    padding-left: 8px;
    width: 80px;
    height: 80px;
  `};
`;
Client.displayName = 'Client';
