import React from 'react';
import get from 'lodash/get';
import head from 'lodash/head';
import { IntlProvider } from 'react-intl';
import collection from 'resources/translations';

const messagesKeys = {};

collection.forEach((translation) => {
  messagesKeys[translation.locale] = translation.keys;
});

function IntlProviderContainer({ children }) {
  const locale = get(navigator, 'language', 'en-US');
  const language = head(locale.split('-'));
  const noTranslation = !collection.some((translation) => translation.locale === language);

  const getLocale = () => (noTranslation ? 'en-US' : locale);
  const getMessageKeys = () => (noTranslation ? messagesKeys.en : messagesKeys[language]);

  return (
    <IntlProvider locale={getLocale()} messages={getMessageKeys()}>
      {children}
    </IntlProvider>
  );
}

IntlProviderContainer.displayName = 'IntlProvider';

export default IntlProviderContainer;
