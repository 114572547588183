import styled from 'styled-components';
import { TitleMedium, TitleSmall } from 'Common/Typography/styled';
import theme from 'Styles/theme';
import { bp } from 'utils/breakpoints';

export const Wrapper = styled.div`
  display: none;
  ${bp('mobile')`display:flex;`};
  margin-top: 250px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  padding-right: ${theme.layout.mobile.paddingRight};
  padding-left: ${theme.layout.mobile.paddingLeft};
`;
Wrapper.displayName = 'Wrapper';

export const Block = styled.div`
  max-width: 300px;
  display: block;
`;
Block.displayName = 'Block';

export const Links = styled.div`
  margin-top: 16px;
  & > :not(:last-child){
    margin-right: 30px;
  }
`;
Links.displayName = 'Links';

export const LinkSmall = styled(TitleSmall)`
  color: ${theme.colors.rose};
`;
LinkSmall.displayName = 'LinkSmall';

export const LinkMedium = styled(TitleMedium)`
  color: ${theme.colors.rose};
`;
LinkMedium.displayName = 'LinkMedium';
