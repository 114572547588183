import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TitleMedium } from 'Common/Typography/styled';
import Wrapper from './styled';

function MobileTexts({ title, role }) {
  return (
    <Wrapper>
      <TitleMedium children={<FormattedMessage id={title} />} />
      <TitleMedium children={<FormattedMessage id={role} />} variation="italic" />
    </Wrapper>
  );
}

export default MobileTexts;
