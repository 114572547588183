import styled from 'styled-components';
import desktopServices from 'Images/desktop/servicios.png';
import tabletServices from 'Images/tablet/servicios.svg';
import mobileServices from 'Images/mobile/servicios.svg';
import theme from 'Styles/theme';
import { bp } from 'utils/breakpoints';
import { H1, hSizes } from 'Common/Typography/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  width: 100%;
  scroll-snap-align: start;
  position: relative;
  overflow: visible;
  padding-top: ${theme.layout.desktop.paddingTop};
  padding-left: ${theme.layout.desktop.paddingLeft};
  padding-right: ${theme.layout.desktop.paddingRight};
  padding-bottom: ${theme.layout.desktop.paddingBottom};
  ${bp('notebook')`
    padding-top: ${theme.layout.notebook.paddingTop};
    padding-left: ${theme.layout.notebook.paddingLeft};
    padding-right: ${theme.layout.notebook.paddingRight};
    padding-bottom: ${theme.layout.notebook.paddingBottom};
  `};
  ${bp('tablet')`
    padding-top: ${theme.layout.tablet.paddingTop};
    padding-left: ${theme.layout.tablet.paddingLeft};
    padding-right: ${theme.layout.tablet.paddingRight};
    padding-bottom: ${theme.layout.tablet.paddingBottom};
  `};
  ${bp('mobile')`
    padding-top: ${theme.layout.mobile.paddingTop};
    padding-left: ${theme.layout.mobile.paddingLeft};
    padding-right: ${theme.layout.mobile.paddingRight};
    padding-bottom: ${theme.layout.mobile.paddingBottom};
  `};
  & > :nth-child(n+2){
    z-index: 2;
  }
`;
Wrapper.displayName = 'Wrapper';

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  max-height: 500px;
  justify-content: space-between;
  overflow: hidden;
  margin: auto 0px;

  & > :nth-child(1){
    margin: 0px 24px 0px 0px;
  }
  & > :nth-child(2){
    margin: auto 24px 0px 0px;
  }
  & > :nth-child(3){
    margin: 0px 24px 0px 0px;
  }
  & > :nth-child(4){
    margin: auto 0px 0px 0px;
  }

  ${bp('notebook')`
    align-items: center;

    & > :nth-child(1){
    margin: 0px 24px 0px 0px;
  }
  & > :nth-child(2){
    margin: 0px 24px 0px 0px;
  }
  & > :nth-child(3){
    margin: 0px 24px 0px 0px;
  }
  & > :nth-child(4){
    margin: 0px 0px 0px 0px;
  }
  `};

  ${bp('tablet')`
    margin-top: 50px;
    max-height: unset;
    justify-content: unset;
    flex-wrap: wrap;
    flex-direction: column;

    & > :nth-child(1){
      margin: 0px 0px 0px auto;
    }

    & > :nth-child(2){
      margin: 24px 0px 0px auto;
    }

    & > :nth-child(3){
      margin: 70px auto 0px 16px;
    }

    & > :nth-child(4){
      margin: 24px auto 0px 16px;
    }
  `};

  ${bp('mobile')`
    margin: auto 0px;
    width: 100%;
    align-items: center;
    flex-direction: column;
    overflow: unset;
    flex-wrap: unset;
    justify-content: center;

    & > :nth-child(1){
      margin: 0px;
    }

    & > :nth-child(2){
      margin: 16px 0px 0px 0px;
    }

    & > :nth-child(3){
      margin: 16px 0px 0px 0px;
    }

    & > :nth-child(4){
      margin: 16px 0px 0px 0px;
    }
  `};

`;
CardsWrapper.displayName = 'CardsWrapper';

export const Title = styled(H1)`
  margin-bottom: 20px;
  ${bp('notebook')`
    ${hSizes.h2};
  `};
  ${bp('mobile')`
    ${hSizes.h3};
  `};
`;
Title.displayName = 'Title';

export const Background = styled.img`
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0px;
  top: 0px;
  z-index: 1;
  width: 100%;
  content: url(${desktopServices});
  ${bp('tablet')`
    content: url(${tabletServices});
  `};
  ${bp('mobile')`
    content: url(${mobileServices});
  `};
`;
Background.displayName = 'Background';
