import styled from 'styled-components';
import desktopTeam from 'Images/desktop/equipodetrabajo.svg';
import tabletTeam from 'Images/tablet/equipodetrabajo.svg';
import mobileTeam from 'Images/mobile/equipodetrabajo.svg';
import theme from 'Styles/theme';
import { bp } from 'utils/breakpoints';
import {
  H1, H3, hSizes, TitleLarge,
} from 'Common/Typography/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  width: 100%;
  scroll-snap-align: start;
  position: relative;
  overflow-y: hidden;
  overflow-x: scroll;
  padding-top: ${theme.layout.desktop.paddingTop};
  padding-left: ${theme.layout.desktop.paddingLeft};
  padding-bottom: ${theme.layout.desktop.paddingBottom};
  ${bp('notebook')`
    padding-top: ${theme.layout.notebook.paddingTop};
    padding-left: ${theme.layout.notebook.paddingLeft};
    padding-bottom: ${theme.layout.notebook.paddingBottom};
  `};
  ${bp('tablet')`
    padding-top: ${theme.layout.tablet.paddingTop};
    padding-left: ${theme.layout.tablet.paddingLeft};
    padding-right: ${theme.layout.tablet.paddingRight};
    padding-bottom: ${theme.layout.tablet.paddingBottom};
  `};
  ${bp('mobile')`
    padding-top: ${theme.layout.mobile.paddingTop};
    padding-left: ${theme.layout.mobile.paddingLeft};
    padding-right: ${theme.layout.mobile.paddingRight};
    padding-bottom: ${theme.layout.mobile.paddingBottom};
  `};
  & > :nth-child(n+2){
    z-index: 2;
  }
`;
Wrapper.displayName = 'Wrapper';

export const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  ${bp('tablet')`
    overflow: hidden;
    flex-direction: column;
  `};
  ${bp('mobile')`
    overflow: hidden;
    flex-direction: column;
  `};
  & > :not(:last-child){
    margin-right: 14px;  
  }
`;
ScrollWrapper.displayName = 'ScrollWrapper';

export const BriefWrapper = styled.div`
  display: flex;
  ${bp('mobile')`display: none;`};
  flex-direction: column;
  flex: 1;
  max-width: 500px;
  overflow: auto;
  & > :not(:last-child){
    margin-right: 14px;  
  }
  ${bp('tablet')`
    flex: 0 0 auto;
  `};
`;
BriefWrapper.displayName = 'BriefWrapper';

export const Brief = styled(H3)`
  margin-top: 40px;
  ${bp('notebook')`
    ${hSizes.h4};
  `};
  ${bp('tablet')`display: none;`};
`;
ScrollWrapper.displayName = 'ScrollWrapper';

export const TabletBrief = styled(TitleLarge)`  
  display: none;
  margin-top: 16px;
  ${bp('tablet')`display: initial;`};
`;
ScrollWrapper.displayName = 'ScrollWrapper';

export const TeamMembersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  flex: 1;
  margin-left: 24px;
  overflow: auto;
  & > * {
    margin-right: 14px;  
  }
  ${bp('tablet')`
    height: unset;
    flex-direction: row;
    margin-left: unset;
    margin-top: 150px;
    justify-content: center;
    flex-wrap: wrap;
    & > * {
      margin-right: unset;
    }
    & > :nth-child(2){
      margin-top: 90px;
    }
    & > :not(:last-child){
      margin-right: auto;
    }
  `};
  ${bp('mobile')`
    height: unset;
    flex: unset;
    flex-direction: row;
    margin-left: unset;
    margin-top: 48px;
    flex-wrap: wrap;
    justify-content: unset;
    & > * {
      margin-right: unset;
    }
    & > :nth-child(2){
      margin-top: 90px;
    }
    & > :not(:last-child){
      margin-right: unset;
    }
  `};
`;
TeamMembersWrapper.displayName = 'TeamMembersWrapper';

export const Title = styled(H1)`
  ${bp('notebook')`
    ${hSizes.h2};
  `};
  ${bp('tablet')`
    ${hSizes.h2};
  `};
  ${bp('mobile')`
    ${hSizes.h3};
  `};
`;
Title.displayName = 'Title';

export const Background = styled.img`
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0px;
  top: 0px;
  z-index: 1;
  width: 100%;
  content: url(${desktopTeam});
  ${bp('tablet')`
    content: url(${tabletTeam});
  `};
  ${bp('mobile')`
    content: url(${mobileTeam});
  `};
`;
Background.displayName = 'Background';
