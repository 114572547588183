import styled from 'styled-components';
import desktopHome from 'Images/desktop/home.png';
import tabletHome from 'Images/tablet/home.png';
import mobileHome from 'Images/mobile/home.png';

import theme from 'Styles/theme';
import { bp } from 'utils/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  width: 100%;
  scroll-snap-align: start;
  position: relative;
  overflow: visible;
  padding-top: ${theme.layout.desktop.paddingTop};
  padding-left: ${theme.layout.desktop.paddingLeft};
  padding-right: ${theme.layout.desktop.paddingRight};
  padding-bottom: ${theme.layout.desktop.paddingBottom};
  ${bp('notebook')`
    padding-top: ${theme.layout.notebook.paddingTop};
    padding-left: ${theme.layout.notebook.paddingLeft};
    padding-right: ${theme.layout.notebook.paddingRight};
    padding-bottom: ${theme.layout.notebook.paddingBottom};
  `};
  ${bp('tablet')`
    padding-top: ${theme.layout.tablet.paddingTop};
    padding-left: ${theme.layout.tablet.paddingLeft};
    padding-right: ${theme.layout.tablet.paddingRight};
    padding-bottom: ${theme.layout.tablet.paddingBottom};
  `};
  ${bp('mobile')`
    padding-top: ${theme.layout.mobile.paddingTop};
    padding-left: ${theme.layout.mobile.paddingLeft};
    padding-right: ${theme.layout.mobile.paddingRight};
    padding-bottom: ${theme.layout.mobile.paddingBottom};
  `};
  & > :nth-child(n+2){
    z-index: 2;
  }
`;
Wrapper.displayName = 'Wrapper';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  width: 100%;
  align-items: center;
`;
ContentWrapper.displayName = 'ContentWrapper';

export const Background = styled.img`
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0px;
  top: 0px;
  z-index: 1;
  width: 100%;
  content: url(${desktopHome});
  ${bp('tablet')`
    content: url(${tabletHome});
  `};
  ${bp('mobile')`
    content: url(${mobileHome});
  `};
`;
Background.displayName = 'Background';
