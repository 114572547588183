const colors = {
  black: '#000000',
  white: '#FFFFFF',
  grey1: '#050505',
  grey2: '#7D7979',
  background: '#0F0A0F',
  rose: '#FFA89F',
  turquoise: '#04C4D9',
};

export default colors;
