import React from 'react';
import DesktopTexts from './Desktop';
import MobileTexts from './Mobile';

function Texts({
  glow, index, isLastElement, title, description,
}) {
  return (
    <>
      <DesktopTexts title={title} description={description} />
      <MobileTexts title={title} description={description} glow={glow} index={index} isLastElement={isLastElement} />
    </>
  );
}

export default Texts;
