import styled from 'styled-components';
import { TitleMedium, TitleSmall } from 'Common/Typography/styled';
import { bp } from 'utils/breakpoints';
import theme from 'Styles/theme';

export const Wrapper = styled.div`
  display: none;
  margin-left: 20px;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: visible;
  ${bp('mobile')`display: flex;`};
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled(TitleMedium)`
  position: relative;
  ${({ glow, index }) => glow && `
    color: ${theme.colors.turquoise};
    transition: color 300ms ease-in;
    transition-delay: ${(index * 300) - 150}ms;
  `};
`;
Title.displayName = 'Title';

export const Dot = styled.div`
  width: 16px;
  content: ' ';
  height: 16px;
  border-radius: 50%;
  position: absolute;
  left: -49px;
  top: 4px;
  background: radial-gradient(50% 50% at 50% 50%, #7DFCCC 0%, #04C4D9 100%);
  filter: blur(2px) drop-shadow(0px 0px 6px #04C4D9);
  opacity: 0;
  ${({ glow, index }) => glow && `
    opacity: 1;    
    transition: opacity 300ms ease-in;
    transition-delay: ${(index * 300) - 150}ms;
  `};
`;

export const VerticalLine = styled.div`
  width: 1px;
  position: absolute;
  left: -49px;
  top: 4px;
  background: ${theme.colors.turquoise};
  height: 0px;
  left: -41px;
  top: 12px;
  ${({ glow, index, isLastElement }) => glow && !isLastElement && `
    height: 98px;
    transition: height 300ms ease-in;
    transition-delay: ${index * 300}ms;
  `};
`;

export const Description = styled(TitleSmall)`
  margin-top: 8px;
`;
Description.displayName = 'Description';
