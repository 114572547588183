import React from 'react';
import {
  Wrapper,
  ProfilePic,
  QuoteText,
  Author,
} from './styled';

function Quote({
  author, quote, pic,
}) {
  return (
    <Wrapper>
      <ProfilePic src={pic} />
      <QuoteText variation="italicLight" children={quote} />
      <Author children={author} />
    </Wrapper>
  );
}

export default Quote;
