import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Wrapper, MobileTitle, TextsWrapper, Background,
} from './styled';
import FollowUs from './components/FollowUs';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import MobileContactUs from './components/MobileContactUs';

function Contact() {
  return (
    <Wrapper id="contact">
      <Background />
      <MobileTitle children={<FormattedMessage id="contact.mobileTitle" />} />
      <MobileContactUs />
      <TextsWrapper>
        <FollowUs />
        <ContactUs />
      </TextsWrapper>
      <Footer />
    </Wrapper>
  );
}

export default Contact;
