import { ReactComponent as Step01 } from 'Images/steps/step_01.svg';
import { ReactComponent as Step02 } from 'Images/steps/step_02.svg';
import { ReactComponent as Step03 } from 'Images/steps/step_03.svg';
import { ReactComponent as Step04 } from 'Images/steps/step_04.svg';

const METHOD_STEPS = [
  {
    image: Step01,
    title: 'workMethod.step.01.title',
    description: {
      desktop: 'workMethod.step.01.desktopDescription',
      mobile: 'workMethod.step.01.mobileDescription',
    },
  },
  {
    image: Step02,
    title: 'workMethod.step.02.title',
    description: {
      desktop: 'workMethod.step.02.desktopDescription',
      mobile: 'workMethod.step.02.mobileDescription',
    },
  },
  {
    image: Step03,
    title: 'workMethod.step.03.title',
    description: {
      desktop: 'workMethod.step.03.desktopDescription',
      mobile: 'workMethod.step.03.mobileDescription',
    },
  },
  {
    image: Step04,
    title: 'workMethod.step.04.title',
    description: {
      desktop: 'workMethod.step.04.desktopDescription',
      mobile: 'workMethod.step.04.mobileDescription',
    },
  },
];

export default METHOD_STEPS;
