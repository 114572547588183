import styled from 'styled-components';
import { TitleMedium } from 'Common/Typography/styled';
import theme from 'Styles/theme';
import { bp } from 'utils/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 90px;
  margin-top: auto;
  align-items: center;
  justify-content: space-evenly;
  background-color: ${theme.colors.black};
  width: 100%;
  padding-left: ${theme.layout.desktop.paddingLeft};
  padding-right: ${theme.layout.desktop.paddingRight};
  ${bp('notebook')`
    padding-left: ${theme.layout.notebook.paddingLeft};
    padding-right: ${theme.layout.notebook.paddingRight};
    justify-content: space-between;
  `};
  ${bp('tablet')`
    padding-left: ${theme.layout.tablet.paddingLeft};
    padding-right: ${theme.layout.tablet.paddingRight};
    justify-content: space-between;
  `};
  ${bp('mobile')`
    padding-left: ${theme.layout.mobile.paddingLeft};
    padding-right: ${theme.layout.mobile.paddingRight};
    justify-content: space-between;
    flex-direction: column;
    justify-content: center;
  `};
`;
Wrapper.displayName = 'Wrapper';

export const MailTo = styled(TitleMedium)`
  text-decoration: none;
  ${bp('mobile')`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-bottom: 8px;
  `};
`;
MailTo.displayName = 'MailTo';

export const Location = styled(TitleMedium)`
  ${bp('mobile')`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  `};
`;
Location.displayName = 'Location';
