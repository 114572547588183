import TurismoCity from 'Images/clientLogos/tc-logo.png';
import PaxAssistance from 'Images/clientLogos/pax-logo.svg';
import HNTBank from 'Images/clientLogos/hntbank-logo.svg';
import Woocar from 'Images/clientLogos/woo-logo.png';
import FareCompare from 'Images/clientLogos/farecompare-logo.png';
import Insigneo from 'Images/clientLogos/insigneo-logo.png';
import MGrana from 'Images/clientLogos/mgrana-logo.png';
import Dasa from 'Images/clientLogos/dasa-logo.png';
import Sidom from 'Images/clientLogos/sidom-logo.svg';
import Mejuri from 'Images/clientLogos/mejuri-logo.svg';
import Faraday from 'Images/clientLogos/faraday_logo.svg';
import Watermelon from 'Images/clientLogos/watermelon-logo.svg';
import Vivre from 'Images/clientLogos/vivre-logo.svg';
import Relex from 'Images/clientLogos/relex-logo.png';
import Kidloom from 'Images/clientLogos/kids-logo.png';
import Vrainz from 'Images/clientLogos/vrainz-logo.png';
import Kickads from 'Images/clientLogos/kickads-logo.png';
import TheFoodMarket from 'Images/clientLogos/tfm-logo.png';
import Emi from 'Images/clientLogos/emi-logo.svg';
import FesInternational from 'Images/clientLogos/fes-logo.svg';

const CLIENTS = [
  {
    title: 'TurismoCity',
    image: TurismoCity,
    web: 'https://www.turismocity.com.ar',
  },
  {
    title: 'Pax Assistance',
    image: PaxAssistance,
    web: 'https://www.paxassistance.com',
  },
  {
    title: 'HNTBank',
    image: HNTBank,
    web: 'https://www.myhntbank.com',
  },
  {
    title: 'Woocar',
    image: Woocar,
    web: 'https://www.woocar.io',
  },
  {
    title: 'FareCompare',
    image: FareCompare,
    web: 'https://www.farecompare.com',
  },
  {
    title: 'Insigneo',
    image: Insigneo,
    web: 'https://insigneo.com',
  },
  {
    title: 'mGrana',
    image: MGrana,
    web: 'https://mgrana.com',
  },
  {
    title: 'Dasa',
    image: Dasa,
    web: 'https://www.documentosaduaneros.com',
  },
  {
    title: 'Sidom',
    image: Sidom,
    web: 'https://sidomsuite.com',
  },
  {
    title: 'Mejuri',
    image: Mejuri,
    web: 'https://mejuri.com',
  },
  {
    title: 'Faraday',
    image: Faraday,
    web: 'https://faradaysec.com',
  },
  {
    title: 'Watermelon',
    image: Watermelon,
    web: 'https://www.watermelonco.es',
  },
  {
    title: 'Vivre',
    image: Vivre,
    web: 'https://vivre.com.ar',
  },
  {
    title: 'Relex',
    image: Relex,
    web: 'https://relex.com.ar',
  },
  {
    title: 'Kidloom',
    image: Kidloom,
    web: 'https://ar.linkedin.com/company/kidloom',
  },
  {
    title: 'Vrainz',
    image: Vrainz,
    web: 'https://www.linkedin.com/company/vrainz',
  },
  {
    title: 'Kickads',
    image: Kickads,
    web: 'https://www.kickads.mobi',
  },
  {
    title: 'TheFoodMarket',
    image: TheFoodMarket,
    web: 'https://www.thefoodmarket.com.ar',
  },
  {
    title: 'Emi',
    image: Emi,
    web: 'https://www.emilabs.ai',
  },
  {
    title: 'FES International',
    image: FesInternational,
    web: 'https://www.fes.de/standorte/fes-international/',
  },
];

export default CLIENTS;
