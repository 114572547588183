import styled from 'styled-components';
import desktopContact from 'Images/desktop/contacto-gradiente-trama.png';
import tabletContact from 'Images/tablet/contacto-gradiente-trama.png';
import mobileContact from 'Images/mobile/contacto-gradiente-trama.png';
import theme from 'Styles/theme';
import { H3 } from 'Common/Typography/styled';
import { bp } from 'utils/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  width: 100%;
  scroll-snap-align: start;
  position: relative;
  overflow: hidden;
  padding-top: ${theme.layout.desktop.paddingTop};
  ${bp('notebook')`
    padding-top: ${theme.layout.notebook.paddingTop};
  `};
  ${bp('tablet')`
    padding-top: ${theme.layout.tablet.paddingTop};
  `};
  ${bp('mobile')`
    padding-top: ${theme.layout.mobile.paddingTop};
  `};
  & > :nth-child(n+2){
    z-index: 2;
  }
`;
Wrapper.displayName = 'Wrapper';

export const TextsWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    ${bp('tablet')`
      flex-direction: column-reverse;
      max-height: 650px;
      margin-top: auto;
      margin-bottom: auto;
  `};
`;
TextsWrapper.displayName = 'TextsWrapper';

export const MobileTitle = styled(H3)`
  display: none;
  ${bp('mobile')`
    display:initial;
    padding-right: ${theme.layout.mobile.paddingRight};
    padding-left: ${theme.layout.mobile.paddingRight};
  `};
`;
MobileTitle.displayName = 'MobileTitle';

export const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
  background-size: cover;
  background-image: url(${desktopContact});
  ${bp('tablet')`
    background-image: url(${tabletContact});
  `};
  ${bp('mobile')`
    background-image: url(${mobileContact});
  `};
`;
Background.displayName = 'Background';
