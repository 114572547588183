import styled, { keyframes } from 'styled-components';
import { ReactComponent as ArrowNext } from 'Images/icons/arrow_next.svg';
import { ReactComponent as ArrowPrev } from 'Images/icons/arrow_prev.svg';
import quote1Desktop from 'Images/desktop/quote_01.png';
import quote2Desktop from 'Images/desktop/quote_02.png';
import quote3Desktop from 'Images/desktop/quote_03.png';
import quote1Tablet from 'Images/tablet/quote_01.png';
import quote2Tablet from 'Images/tablet/quote_02.png';
import quote3Tablet from 'Images/tablet/quote_03.png';
import quote1Mobile from 'Images/mobile/quote_01.png';
import quote2Mobile from 'Images/mobile/quote_02.png';
import quote3Mobile from 'Images/mobile/quote_03.png';
import { bp } from 'utils/breakpoints';
import theme from 'Styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 0 0 100%;
  width: 100%;
  overflow: hidden;
  scroll-snap-align: start;
  padding-top: ${theme.layout.desktop.paddingTop};
  padding-left: ${theme.layout.desktop.paddingLeft};
  padding-right: ${theme.layout.desktop.paddingRight};
  padding-bottom: ${theme.layout.desktop.paddingBottom};
  ${bp('notebook')`
    padding-top: ${theme.layout.notebook.paddingTop};
    padding-left: ${theme.layout.notebook.paddingLeft};
    padding-right: ${theme.layout.notebook.paddingRight};
    padding-bottom: ${theme.layout.notebook.paddingBottom};
  `};
  ${bp('tablet')`
    padding-top: ${theme.layout.tablet.paddingTop};
    padding-left: ${theme.layout.tablet.paddingLeft};
    padding-right: ${theme.layout.tablet.paddingRight};
    padding-bottom: ${theme.layout.tablet.paddingBottom};
  `};
  ${bp('mobile')`
    padding-top: ${theme.layout.mobile.paddingTop};
    padding-left: ${theme.layout.mobile.paddingLeft};
    padding-right: ${theme.layout.mobile.paddingRight};
    padding-bottom: 42px;
  `};
  & > :nth-child(n+2){
    z-index: 2;
  }
`;
Wrapper.displayName = 'Wrapper';

export const SliderWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
`;
SliderWrapper.displayName = 'SliderWrapper';

export const Slider = styled.div.attrs(({ hasTransitionClass }) => {
  if (hasTransitionClass) return ({ style: { transition: 'ease all 0.35s' } });
  return ({});
})`
  height: 100%;
  width: 100%; 
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
`;
Wrapper.displayName = 'Wrapper';

export const Next = styled(ArrowNext)`
  position: absolute;
  right: 100px;
  top: 50%;
  z-index: 3 !important;
  cursor: pointer;
  ${bp('mobile')`
    right: 16px;
    top: unset;
    bottom: 50px;
    transform: translateY(50%);
  `};
`;
Next.displayName = 'Next';

export const Prev = styled(ArrowPrev)`
  position: absolute;
  top: 50%;
  z-index: 3 !important;
  cursor: pointer;
  left: 100px;
  ${bp('mobile')`
    left: 16px;
    top: unset;
    bottom: 50px;
    transform: translateY(50%);
  `};
`;
Prev.displayName = 'Prev';

export const DotsWrapper = styled.div`
  flex: 0 0 auto;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 3 !important;
  & > :not(:last-child){
    margin-right: 24px;
  }
  ${bp('mobile')`
    bottom: 50px;
  `};
`;
DotsWrapper.displayName = 'DotsWrapper';

export const Dot = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${theme.colors.white};
  transition: background-color 350ms ease-in-out;
  background-color: ${({ checked }) => (checked ? `${theme.colors.white}` : 'transparent')};
  ${bp('mobile')`
    width: 10px;
    height: 10px;
  `};
`;
Dot.displayName = 'Dot';

const backgroundDesktop = keyframes`
  0% { background-image: url(${quote1Desktop}); }
  25% { background-image: url(${quote2Desktop}); }
  50% { background-image: url(${quote3Desktop}); }
  75% { background-image: url(${quote2Desktop}); }
  100% { background-image: url(${quote1Desktop}); }
`;

const backgroundTablet = keyframes`
  0% { background-image: url(${quote1Tablet}); }
  25% { background-image: url(${quote2Tablet}); }
  50% { background-image: url(${quote3Tablet}); }
  75% { background-image: url(${quote2Tablet}); }
  100% { background-image: url(${quote1Tablet}); }
`;

const backgroundMobile = keyframes`
  0% { background-image: url(${quote1Mobile}); }
  25% { background-image: url(${quote2Mobile}); }
  50% { background-image: url(${quote3Mobile}); }
  75% { background-image: url(${quote2Mobile}); }
  100% { background-image: url(${quote1Mobile}); }
`;

export const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
  background-size: cover;
  animation: ${backgroundDesktop} 12s infinite;
  ${bp('tablet')`
    animation: ${backgroundTablet} 12s infinite;
  `};
  ${bp('mobile')`
    animation: ${backgroundMobile} 12s infinite;
  `};
`;
Background.displayName = 'Background';
