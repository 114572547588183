import Test1 from 'Images/testProfPic1.png';
import Test2 from 'Images/testProfPic2.png';

const TEAM_MEMBERS = [
  {
    image: Test1,
    title: 'team.member.nico.name',
    role: 'team.member.nico.role',
    description: 'team.member.nico.description',
  },
  {
    image: Test2,
    title: 'team.member.joa.name',
    role: 'team.member.joa.role',
    description: 'team.member.joa.description',
  },
  {
    image: Test1,
    title: 'team.member.benja.name',
    role: 'team.member.benja.role',
    description: 'team.member.benja.description',
  },
];

export default TEAM_MEMBERS;
