import styled from 'styled-components';
import { bp } from 'utils/breakpoints';

const Wrapper = styled.div`
  display: none;
  ${bp('mobile')` display: flex;`};
  height: 100%;
  width: 100%;
  bottom: 0px;
  left: 0px;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  margin-top: 8px;
  & > :last-child{
    margin-top: 6px;
  }
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
