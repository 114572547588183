import styled from 'styled-components';
import { BodyLarge, H6, bodySizes } from 'Common/Typography/styled';
import theme from 'Styles/theme';
import { bp } from 'utils/breakpoints';

export const Wrapper = styled.div`
  max-width: 320px;
  max-height: 420px;
  flex: 1;


  display: flex;
  flex-direction: column;
  /* aspect-ratio: 3 / 4; */
  height: calc(100% - 70px);
  padding: 24px;
  border: 4px solid rgba(255, 255, 255, 0.2);
  background: ${theme.colors.grey1};
  background: rgba(30, 30, 30, 0.6);
  border-radius: 14px;
  align-items: center;
  transition: background 200ms ease-in-out;
  :hover{
    background: rgba(50, 50, 50, 0.5);
  }
  ${bp('notebook')`
    height: 100%;
  `};
  ${bp('tablet')`
    aspect-ratio: unset;
    max-width: 266px;
    width: 100%;
    padding: 24px;
    flex: 0 0 calc(50% - 48px);
    :hover{
      background: rgba(30, 30, 30, 0.6);
    }
  `};
  ${bp('mobile')`
    max-width: unset;
    padding: 14px 8px;
    flex: 0 0 104px;
    flex-direction: row;
  `};
`;
Wrapper.displayName = 'Wrapper';

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  max-height: 160px;
  aspect-ratio: 1 / 1;
  align-items: center;
  justify-content: center;
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  ${bp('notebook')`
    max-height: 125px;
  `};
  ${bp('mobile')`
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    & > * {
      height: 27px;
      width: 27px;
    }
  `};
`;
IconWrapper.displayName = 'IconWrapper';

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 3;
  width: 100%;
  margin-top: 32px;
  ${bp('notebook')`
    margin-top: 16px;
  `};
  ${bp('tablet')`
    margin-top: 16px;
  `};
  ${bp('mobile')`
    margin-left: 16px;
    margin-top: unset;
  `};
`;
TextWrapper.displayName = 'TextWrapper';

export const Title = styled(H6)`
`;
Title.displayName = 'Title';

export const Description = styled(BodyLarge)`
  margin-top: 10px;
  ${bp('notebook')`
    ${bodySizes.medium};
  `};
  ${bp('tablet')`
    ${bodySizes.medium};
  `};
  ${bp('mobile')`
    display: none;
  `};
`;
Description.displayName = 'Description';
