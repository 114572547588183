/**
 * Breakpoints configuration
 *
 * @return object
 */

export default {
  notebook: '(max-width: 1440px)',
  tablet: '(max-width: 850px)',
  mobile: '(max-width: 480px)',
};
