import React from 'react';
import { FormattedMessage } from 'react-intl';
import Whatsapp from './Whatsapp';
import {
  Wrapper, MailTo, Location,
} from './styled';

function Footer() {
  return (
    <Wrapper>
      <Whatsapp />
      <MailTo as="a" href="mailto:contacto@brosoft.com.ar" target="_blank" children="contacto@brosoft.com.ar" />
      <Location children={<FormattedMessage id="contact.location" />} />
    </Wrapper>
  );
}

export default Footer;
