import styled from 'styled-components';
import theme from 'Styles/theme';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${theme.colors.background};
  overflow: hidden;
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
