import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import useVerticalScroll from 'Hooks/useVerticalScroll';
import SideIndex from 'Common/SideIndex';
import useFade from 'Hooks/useFade';
import CLIENTS from './clients';
import {
  Wrapper, ViewWrapper, Title, Subtitle, Background, ClientsWrapper, Client,
} from './styled';

function Clients() {
  const [wrapperRef, scrollRef] = useVerticalScroll();
  const indexOpacity = useFade(wrapperRef, 1, 0);

  return (
    <Wrapper ref={wrapperRef}>
      <Background />
      <ViewWrapper>
        <SideIndex textId="clients.sideIndex" opacity={indexOpacity} />
        <Title children={<FormattedMessage id="clients.title" />} />
        <Subtitle children={<FormattedMessage id="clients.subtitle" />} />
        <ClientsWrapper ref={scrollRef}>
          {CLIENTS.map(({ title, image, web }) => {
            const clientRef = useRef(null);
            const clientOpacity = useFade(clientRef, 1, 0.2);

            return (
              <Client
                key={title}
                image={image}
                href={web}
                target="_blank"
                ref={clientRef}
                style={{ opacity: clientOpacity }}
              />
            );
          })}
        </ClientsWrapper>
      </ViewWrapper>
    </Wrapper>
  );
}

export default React.memo(Clients);
