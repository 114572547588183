import styled from 'styled-components';
import { bp } from 'utils/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  scroll-snap-align: start;
  position: relative;
  overflow: hidden;
  scroll-snap-type: both mandatory;
  overflow-y: auto;

  ${bp('tablet')`
    scroll-snap-type: both mandatory;
  `};
  ${bp('mobile')`
    scroll-snap-type: both mandatory;
  `};
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
