import React from 'react';
import { useIntl } from 'react-intl';
import RotatedText from './styled';

function SideIndex({ textId = '', opacity = 1 }) {
  const intl = useIntl();
  const formattedLabel = textId ? intl.formatMessage({ id: textId, defaultMessage: '-' }) : '';

  return (
    <RotatedText opacity={opacity}>
      { formattedLabel }
    </RotatedText>
  );
}

export default SideIndex;
