import { css } from 'styled-components';
import breakpoints from 'utils/breakpoints/rules';

/**
 * Wraps styles in css media query
 *
 * @param id
 * @param type
 * @returns string
 */

export const bp = (id) => {
  // eslint-disable-next-line react/display-name
  if (!id) return () => null;
  /* istanbul ignore next */
  const breakpoint = breakpoints[id];
  if (!breakpoint) {
    console.warn(`Breakpoint ${id} does not exist.`); // eslint-disable-line
    // eslint-disable-next-line react/display-name
    return () => null;
  }

  return (...args) => css`
    @media ${breakpoint} {
      ${css(...args)}
    }
  `;
};

/**
 * Wraps styles in css media query only if a breakpoint exists, otherwise outputs a normal style (d is for default).
 *
 * @param from - Breakpoint
 * @returns string
 */

export const bpd = (from) => (...rest) => (from ? bp(from)`${rest}` : () => rest);
