import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Wrapper, TextWrapper, Title, Description, IconWrapper,
} from './styled';

function Card({ Icon, title, description }) {
  return (
    <Wrapper>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <TextWrapper>
        <Title children={<FormattedMessage id={title} />} />
        <Description children={<FormattedMessage id={description} />} />
      </TextWrapper>
    </Wrapper>
  );
}

export default Card;
