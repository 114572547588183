import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TitleMedium } from 'Common/Typography/styled';
import {
  Wrapper, Links, Block, LinkSmall, LinkMedium,
} from './styled';
import Brochure from '../../../../Documents/brochure.pdf';

function MobileContactUs() {
  return (
    <Wrapper>
      <Block>
        <TitleMedium children={<FormattedMessage id="contact.description" />} />
        <LinkMedium as="a" href={Brochure} target="_blank" children="showcases" download="BrosoftShowcases" />
      </Block>
      <Links>
        <LinkSmall as="a" href="https://www.linkedin.com/company/brosoft-ar" target="_blank" children="LinkedIn" />
        <LinkSmall as="a" href="https://instagram.com/brosoftar" target="_blank" children="Instagram" />
      </Links>
    </Wrapper>
  );
}

export default MobileContactUs;
