import React from 'react';
import { FormattedMessage } from 'react-intl';
import useHorizontalScroll from 'Hooks/useHorizontalScroll';
import SideIndex from 'Common/SideIndex';
import useFade from 'Hooks/useFade';
import METHOD_STEPS from './steps';
import Step from './Step';
import {
  Wrapper, MethodStepsWrapper, Title,
} from './styled';

function WorkMethod() {
  const [wrapperRef, scrollRef] = useHorizontalScroll();
  const opacity = useFade(wrapperRef, 1, 0);

  return (
    <Wrapper ref={wrapperRef}>
      <SideIndex textId="workMethod.sideIndex" opacity={opacity} />
      <Title children={<FormattedMessage id="workMethod.title" />} />
      <MethodStepsWrapper ref={scrollRef}>
        { METHOD_STEPS.map(({ image, title, description }, index) => <Step key={title} Image={image} title={title} description={description} index={index} isLastElement={(index + 1) === METHOD_STEPS.length} />) }
      </MethodStepsWrapper>
    </Wrapper>
  );
}

export default WorkMethod;
