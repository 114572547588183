import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Wrapper, Description, Title,
  Role, RoleWrapper, Minus, Plus,
} from './styled';

function DesktopTexts({
  title, role, description, isExpanded,
}) {
  return (
    <Wrapper isExpanded={isExpanded}>
      <Title children={<FormattedMessage id={title} />} />
      <RoleWrapper>
        <Role children={<FormattedMessage id={role} />} variation="italic" />
        { isExpanded ? <Minus /> : <Plus />}
      </RoleWrapper>
      <Description isExpanded={isExpanded} children={<FormattedMessage id={description} />} />
    </Wrapper>
  );
}

export default DesktopTexts;
