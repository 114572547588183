import React from 'react';
import { useIntl } from 'react-intl';
import StyledAnchor from './styled';

function AnchorTag({ tagId = null, textId = '', titleId = '' }) {
  const intl = useIntl();
  const formattedTitle = titleId ? intl.formatMessage({ id: titleId, defaultMessage: '-' }) : '';
  const formattedLabel = textId ? intl.formatMessage({ id: textId, defaultMessage: '-' }) : '';
  const scrollToElement = () => document.getElementById(tagId)?.scrollIntoView({ behavior: 'smooth' });

  return (
    <StyledAnchor onClick={scrollToElement} title={formattedTitle}>
      { formattedLabel }
    </StyledAnchor>
  );
}

export default AnchorTag;
