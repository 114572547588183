import React from 'react';
import Wrapper from 'styled';
import Main from './Screens/Main';

function App() {
  return (
    <Wrapper>
      <Main />
    </Wrapper>
  );
}

export default App;
