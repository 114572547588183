import React, { useState } from 'react';
import { Wrapper, ImageWrapper, Image } from './styled';
import Texts from './Texts';

function Member({
  image, title, role, description,
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const expand = () => setIsExpanded(true);
  const collapse = () => setIsExpanded(false);
  return (
    <Wrapper onClick={() => setIsExpanded(!isExpanded)} onMouseEnter={expand} onMouseLeave={collapse}>
      <ImageWrapper children={<Image src={image} alt="" />} />
      <Texts title={title} role={role} description={description} isExpanded={isExpanded} />
    </Wrapper>
  );
}

export default React.memo(Member);
