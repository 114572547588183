import styled from 'styled-components';
import theme from 'Styles/theme';

export const hSizes = {
  h1: 'font-size: 57px; line-height: 64px;',
  h2: 'font-size: 45px; line-height: 52px;',
  h3: 'font-size: 36px; line-height: 44px;',
  h4: 'font-size: 32px; line-height: 40px;',
  h5: 'font-size: 28px; line-height: 36px;',
  h6: 'font-size: 24px; line-height: 32px;',
};

export const titleSizes = {
  large: 'font-size: 22px; line-height: 28px; letter-spacing: 0px;',
  medium: 'font-size: 18px; line-height: 26px; letter-spacing: 0.15px;',
  small: 'font-size: 14px; line-height: 20px; letter-spacing: 0.1px;',
};

export const bodySizes = {
  large: 'font-size: 16px; line-height: 24px; letter-spacing: 0.15px;',
  medium: 'font-size: 14px; line-height: 20px; letter-spacing: 0.25px;',
  small: 'font-size: 12px; line-height: 16px; letter-spacing: 0.4px;',
};

export const H1 = styled.span`
  color: ${theme.colors.white};
  font-family: 'Zilla Slab';
  font-style: normal;
  font-weight: 400;
  ${hSizes.h1};
  letter-spacing: -0.25px;
  ${({ variation }) => variation === 'italic' && `
    font-style: italic;
    font-weight: 600;
  `}
  ${({ variation }) => variation === 'italicLight' && `
    font-style: italic;
    font-weight: 500;
  `}
`;
H1.displayName = 'H1';

export const H2 = styled(H1)`
  ${hSizes.h2};
`;
H2.displayName = 'H2';

export const H3 = styled(H1)`
  ${hSizes.h3};
`;
H3.displayName = 'H3';

export const H4 = styled(H1)`
  ${hSizes.h4};
`;
H4.displayName = 'H4';

export const H5 = styled(H1)`
  ${hSizes.h5};
`;
H5.displayName = 'H5';

export const H6 = styled(H1)`
  ${hSizes.h6};
`;
H6.displayName = 'H6';

export const TitleLarge = styled.span`
  color: ${theme.colors.white};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  ${({ variation }) => variation === 'italic' && 'font-style: italic;'}
  ${titleSizes.large};
`;
TitleLarge.displayName = 'TitleLarge';

export const TitleMedium = styled(TitleLarge)`
  ${titleSizes.medium};
`;
TitleMedium.displayName = 'TitleMedium';

export const TitleSmall = styled(TitleLarge)`
  ${titleSizes.small};
`;
TitleSmall.displayName = 'TitleSmall';

export const LabelLarge = styled.span`
  color: ${theme.colors.white};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
`;
LabelLarge.displayName = 'LabelLarge';

export const LabelMedium = styled(LabelLarge)`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
`;
LabelMedium.displayName = 'LabelMedium';

export const LabelSmall = styled(LabelLarge)`
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.5px;
`;
LabelSmall.displayName = 'LabelSmall';

export const BodyLarge = styled.span`
  color: ${theme.colors.white};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
`;
BodyLarge.displayName = 'BodyLarge';

export const BodyMedium = styled(BodyLarge)`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
`;
BodyMedium.displayName = 'BodyMedium';

export const BodySmall = styled(BodyLarge)`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
`;
BodySmall.displayName = 'BodySmall';
