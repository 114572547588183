import React from 'react';
import { FormattedMessage } from 'react-intl';
import useHorizontalScroll from 'Hooks/useHorizontalScroll';
import SideIndex from 'Common/SideIndex';
import useFade from 'Hooks/useFade';
import TEAM_MEMBERS from './teamMembers';
import Member from './Member';
import {
  Wrapper, ScrollWrapper, TeamMembersWrapper, BriefWrapper, Brief, TabletBrief, Title, Background,
} from './styled';

function Team() {
  const [wrapperRef, scrollRef] = useHorizontalScroll();
  const opacity = useFade(wrapperRef, 1, 0);
  return (
    <Wrapper ref={wrapperRef}>
      <Background />
      <SideIndex textId="team.sideIndex" opacity={opacity} />
      <Title children={<FormattedMessage id="team.title" />} />
      <ScrollWrapper>
        <BriefWrapper>
          <Brief children={<FormattedMessage id="team.description" />} />
          <TabletBrief children={<FormattedMessage id="team.description" />} />
        </BriefWrapper>
        <TeamMembersWrapper ref={scrollRef}>
          { TEAM_MEMBERS.map(({
            image, title, role, description,
          }) => <Member key={title} image={image} title={title} role={role} description={description} />) }
        </TeamMembersWrapper>
      </ScrollWrapper>
    </Wrapper>
  );
}

export default Team;
