import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Wrapper, Title, Description, Dot, VerticalLine,
} from './styled';

function MobileTexts({
  glow, index, isLastElement, title, description,
}) {
  return (
    <Wrapper>
      <VerticalLine glow={glow} index={index} isLastElement={isLastElement} />
      <Dot glow={glow} index={index} />
      <Title children={<FormattedMessage id={title} />} glow={glow} index={index} />
      <Description children={<FormattedMessage id={description.mobile} />} />
    </Wrapper>
  );
}

export default MobileTexts;
