import { useEffect, useRef } from 'react';

const useVerticalScroll = (elemType = '') => {
  const refToListen = useRef(null);
  const refToScroll = useRef(null);

  const scrollVertically = (e) => {
    e.preventDefault();
    const maxScrollTop = refToScroll.current.scrollHeight - refToScroll.current.clientHeight;
    const nextStep = refToScroll.current.scrollTop + e.deltaY;

    if ((maxScrollTop < nextStep && elemType !== 'last') || (nextStep < 0 && elemType !== 'first')) {
      refToScroll.current.scrollTop = nextStep;
      refToListen.current.removeEventListener('wheel', scrollVertically, false);
    } else refToScroll.current.scrollTop = nextStep;
  };

  const keyEvent = (e) => {
    const stepHeight = 500;

    if (e.key === 'ArrowDown') {
      const pendingScroll = refToScroll.current.scrollHeight - refToScroll.current.clientHeight - refToScroll.current.scrollTop;
      if (pendingScroll === 0) window.removeEventListener('keydown', keyEvent);
      else {
        e.preventDefault();
        refToScroll.current.scrollBy({ top: stepHeight, behavior: 'smooth' });
      }
    }

    if (e.key === 'ArrowUp') {
      if (refToScroll.current.scrollTop === 0) window.removeEventListener('keydown', keyEvent);
      else {
        e.preventDefault();
        refToScroll.current.scrollBy({ top: -stepHeight, behavior: 'smooth' });
      }
    }
  };

  const observer = new IntersectionObserver(((entries) => {
    if (entries[0].intersectionRatio === 1) window.addEventListener('keydown', keyEvent);
    else window.removeEventListener('keydown', keyEvent);

    if (entries[0].intersectionRatio >= 0.2) refToListen.current.addEventListener('wheel', scrollVertically, false);
    else refToListen.current.removeEventListener('wheel', scrollVertically, false);
  }), { threshold: [0.2, 1] });

  useEffect(() => {
    if (refToListen.current) observer.observe(refToListen.current);
    return () => {
      if (refToListen.current) {
        observer.unobserve(refToListen.current);
      }
    };
  }, []);

  return [refToListen, refToScroll];
};

export default useVerticalScroll;
