import React from 'react';
import DesktopTexts from './Desktop';
import MobileTexts from './Mobile';

function Texts({
  title, description, role, isExpanded,
}) {
  return (
    <>
      <DesktopTexts title={title} description={description} role={role} isExpanded={isExpanded} />
      <MobileTexts title={title} role={role} />
    </>
  );
}

export default Texts;
