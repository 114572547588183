import styled from 'styled-components';
import { bp } from 'utils/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  align-items: center;
  ${bp('tablet')`    
    height: 424px;
  `};
  ${bp('mobile')`
    height: 188px;
    width: 50%;
  `};
`;
Wrapper.displayName = 'Wrapper';

export const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  overflow: hidden;
  ${bp('mobile')`    
      object-fit: cover;
      object-position: 0 30%;
      flex: 0 0 128px;
      height: 128px;
      width: 128px;
  `};
`;
ImageWrapper.displayName = 'ImageWrapper';

export const Image = styled.img`
  object-fit: scale-down;
  height: 100%;
  ${bp('mobile')`    
      object-fit: cover;
      object-position: 0 30%;
      height: 128px;
      width: 128px;
  `};
`;
Image.displayName = 'Image';
