import { useEffect, useState } from 'react';

const useFade = (refToListen, opacityWhenVisible = 1, opacityWhenNotVisible = 0) => {
  const [opacity, setOpacity] = useState(opacityWhenNotVisible);

  const observer = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting === true) {
      setOpacity(opacityWhenVisible);
    } else {
      setOpacity(opacityWhenNotVisible);
    }
  }, { threshold: [0.5] });

  useEffect(() => {
    if (refToListen.current) {
      observer.observe(refToListen.current);
    }
    return () => {
      if (refToListen.current) {
        observer.unobserve(refToListen.current);
      }
    };
  }, [refToListen, opacityWhenVisible, opacityWhenNotVisible]);

  return opacity;
};

export default useFade;
