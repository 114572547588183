import { useEffect, useRef } from 'react';

const useHorizontalScroll = (elemType = '') => {
  const refToListen = useRef(null);
  const refToScroll = useRef(null);

  const scrollHorizontally = (e) => {
    e.preventDefault();
    const maxScrollLeft = refToScroll.current.scrollWidth - refToScroll.current.clientWidth;
    const nextStep = refToScroll.current.scrollLeft + e.deltaY;
    if ((maxScrollLeft < nextStep && elemType !== 'last') || (nextStep < 0 && elemType !== 'first')) {
      refToScroll.current.scrollLeft = nextStep;
      refToListen.current.removeEventListener('wheel', scrollHorizontally, false);
    } else refToScroll.current.scrollLeft = nextStep;
  };

  const keyEvent = (e) => {
    const stepWidth = 500;

    if (e.key === 'ArrowDown') {
      const pendingScroll = refToScroll.current.scrollWidth - refToScroll.current.clientWidth - refToScroll.current.scrollLeft;
      if (pendingScroll === 0) window.removeEventListener('keydown', keyEvent);
      else {
        e.preventDefault();
        refToScroll.current.scrollBy({ left: stepWidth, behavior: 'smooth' });
      }
    }

    if (e.key === 'ArrowUp') {
      if (refToScroll.current.scrollLeft === 0) window.removeEventListener('keydown', keyEvent);
      else {
        e.preventDefault();
        refToScroll.current.scrollBy({ left: -stepWidth, behavior: 'smooth' });
      }
    }
  };

  const observer = new IntersectionObserver(((entries) => {
    if (entries[0].intersectionRatio === 1) window.addEventListener('keydown', keyEvent);
    else window.removeEventListener('keydown', keyEvent);

    if (entries[0].intersectionRatio >= 0.2) refToListen.current.addEventListener('wheel', scrollHorizontally, false);
    else refToListen.current.removeEventListener('wheel', scrollHorizontally, false);
  }), { threshold: [0.2, 1] });

  useEffect(() => {
    if (refToListen.current) observer.observe(refToListen.current);
  }, []);

  return [refToListen, refToScroll];
};

export default useHorizontalScroll;
