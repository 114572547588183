import styled, { css } from 'styled-components';
import theme from 'Styles/theme';
import { bp } from 'utils/breakpoints';
import { H1, hSizes } from 'Common/Typography/styled';

export const verticalLine = css`
  &:before {
    content: '';
    height: calc(100% - 74px);
    width: 1px;
    position: absolute;
    left: 0px;
    background: ${theme.colors.turquoise};
    margin-left: 28px;
    margin-right: 28px;
    top: 4px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  width: 100%;
  scroll-snap-align: start;
  position: relative;
  overflow: hidden;
  padding-top: ${theme.layout.desktop.paddingTop};
  padding-left: ${theme.layout.desktop.paddingLeft};
  padding-right: ${theme.layout.desktop.paddingRight};
  padding-bottom: ${theme.layout.desktop.paddingBottom};
  ${bp('notebook')`
    padding-top: ${theme.layout.notebook.paddingTop};
    padding-left: ${theme.layout.notebook.paddingLeft};
    padding-right: ${theme.layout.notebook.paddingRight};
    padding-bottom: ${theme.layout.notebook.paddingBottom};
  `};
  ${bp('tablet')`
    padding-top: ${theme.layout.tablet.paddingTop};
    padding-left: ${theme.layout.tablet.paddingLeft};
    padding-right: ${theme.layout.tablet.paddingRight};
    padding-bottom: ${theme.layout.tablet.paddingBottom};
  `};
  ${bp('mobile')`
    padding-top: ${theme.layout.mobile.paddingTop};
    padding-left: ${theme.layout.mobile.paddingLeft};
    padding-right: ${theme.layout.mobile.paddingRight};
    padding-bottom: ${theme.layout.mobile.paddingBottom};
  `};
`;
Wrapper.displayName = 'Wrapper';

export const MethodStepsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  margin-top: 80px;
  margin-left: 130px;
  overflow: auto;
  & > :not(:last-child){
    margin-right: 30px;
  }
  ${bp('notebook')`
    margin-top: 50px;
    margin-left: 50px;
  `};
  ${bp('tablet')`
    margin-left: unset;
    & > :not(:last-child){
      margin-right: 50px;  
    }
  `};
  ${bp('mobile')`
    width: 100%;
    align-items: center;
    margin-top: 64px;
    margin-left: unset;
    flex: 1;
    flex-direction: column;
    overflow: visible;
    position: relative;
    padding-left: 49px;
    & > :not(:last-child){
      margin-bottom: 24px;  
      margin-right: unset;  
    }
  `};
`;
MethodStepsWrapper.displayName = 'MethodStepsWrapper';

export const Title = styled(H1)`
  ${bp('notebook')`
    ${hSizes.h2};
  `};
  ${bp('tablet')`
    ${hSizes.h2};
  `};
  ${bp('mobile')`
    ${hSizes.h3};
  `};
`;
Title.displayName = 'Title';
